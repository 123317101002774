import React, { useEffect, useState } from "react";
import { GetAllContent } from "../api/MainRequests";

export default function PakwachFm(props){
    const [heading1, setHeading1] = useState("")
    const [heading2, setHeading2] = useState("")
    useEffect(() => {
        GetAllContent().then(res =>{
            let heading = []
            let heading2 = []
            for(var i =0; i<res.length; i++){
                if(res[i].name === "Heading1"){
                    heading.push(res[i])
                }
            }
            for(var j =0; j<res.length; j++){
                if(res[j].name === "Heading2"){
                    heading2.push(res[j])
                }
            }
            setHeading1(heading[heading.length - 1])
            setHeading2(heading2[heading2.length - 1])
        })
    },[])
    return(
        <div className="top">
            <div className="row mt-5">
            <div className="col-lg-10 col-sm-12 bg-white p-0 rounded-2">
                <div className="row">
                <div className="col-lg-7 col-sm-12">
                    <img src={heading1.content_picture} width="100%" height="100%" alt="heading1"/>
                    
                </div>
                <div className="col-lg-5 col-sm-12 px-lg-5 px-4 text-start">
                <h6>news headlines</h6>
                <h2 className="lh-1 text-start py-3 display-5 bolder">{heading1.title}</h2>
                    <h6 className="text-start pointer">{heading1.date} | PAKWACH FM</h6><div className="d-flex flex-row">
                    <u><h6 className="bolder pointer" onClick={props.handletab4}>read full article</h6></u></div>
                    <div>
                    <div></div>
                    </div>
                </div>
                </div>
                <div className="row mt-3">
                <div className="col-lg-7">
                <img src={heading2.content_picture} width="100%" height="100%" alt="heading2"/>
                </div>
                <div className="col-lg-5 px-5 p-3 text-start">
                <h6>headlines</h6>
                    <h5 className="lh-1 text-start py-3 display-6 bolder">{heading2.title}</h5>
                    <h6 className="text-start pointer">{heading1.date} | PAKWACH FM</h6></div>
                    </div>
                    <div>
                    </div>
            </div>
                <div className="col-lg-2 px-2">
                <div className="row presenters-index p-5 rounded-2 pointer" onClick={props.handletab6}>
                <h6 className="text-start text-white my-3">in studio with you.... | find out who is bringing you the best gossip | the best music...</h6>
                    <h6 className="mt-5 text-start pt-5 text-white">presenters</h6>
                    <h6 className=" p-2 border-start text-white">check em' out</h6>
                </div>
                <div onClick={props.handletab7} className="row pointer bg-pakwach mt-2 p-lg-5 p-3 rounded-2 d-none d-md-block d-lg-block">
                {/* <img src={Megaphone} width="100%" height="100%"/> */}
                    <h5 className="text-start lh-1 text-white">Let us take your business to the greater westnile</h5>
                    <h6 className="p-2 border-start text-start text-white">advertise your business</h6>
                </div>
                </div>
            </div>
                <div className="mt-3 rounded-2 bg-white border-top border-5 border-black">
                <h6 className="p-3 presenters-index w-25 d-none d-md-block d-d-lg-block">pakwach fm | services</h6>
                <h6 className="p-3 presenters-index w-75 d-sm-block d-md-none d-d-lg-none">pakwach fm | services</h6>
                <div className="p-lg-5 p-3">
                    <div className="row">
                        <div className="col-lg-4 p-lg-5 text-start">
                        <h6 className="border-start px-3">advertising</h6>
                            <h5 className="lh-1">All advertisements including Campaign ads, Health, Banking and Finance, Beverages ...</h5>
                        </div>
                        <div className="col-lg-4 p-2 text-start">
                        <div className="row p-lg-5">
                            <h6 className="border-start px-3">community dialogues</h6>
                            <h5 className="lh-1">We offer a platform for communities to have open, respectful, and solution oriented conversations about health-related issues...</h5>
                            </div>
                        </div>
                        <div className="col-lg-4 p-lg-5 text-start">
                            <h6 className="border-start px-3">talk shows</h6>
                            <h5 className="lh-1">Pakwach FM offers various talk show programs with the very best setting and presentations</h5>
                        </div>
                    </div>
                    <div className="d-flex flex-row">
                        <h6 className="lh-1 small border-start px-3 mx-lg-5 mx-2 text-start d-none d-d-md-block d-lg-block">we offer lots of services whatever fits your pick, get in touch with us and we shall guarantee the best for you. Dj mentions, broadcasting both indoor and outdoor... and so much more</h6>
                        <h6 className="p-2 px-5 active" onClick={props.handletab3}>more services</h6>
                    </div>
                </div>
                </div>
        </div>
    )
}